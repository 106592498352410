import React from "react";

const ProductAvailable:React.FC<{count: number, dateAvailable: string | null}> = ({count, dateAvailable}) => {

    let info = '';

    if (count > 20){
        info = '>20';
    }
    else if (count === 0 && dateAvailable){
        info = dateAvailable
    }
    else {
        info = count.toString();
    }

    return (
        <span>{info}</span>
    )

}

export default ProductAvailable